import type { SynopsisMinimal } from "../../entities/synopses/SynopsisMinimal";
import type { TSynopsisSearchResponse } from "../synopses/SynopsesService";

import { agent } from "../../agent";
import { mapSynopsisSearchResult } from "../synopses/SynopsesService";

export class SearchService {
    sections = async (query: string): Promise<SearchService.SectionsSearchResult> => {
        const params = new URLSearchParams({ search: query });
        const { data } = await agent.get<SearchService.SectionsSearchResult>("/search/sections/", { params });

        return data;
    };

    subsections = async (query: string): Promise<SearchService.SubsectionsSearchResult> => {
        const params = new URLSearchParams({ search: query });
        const { data } = await agent.get<SearchService.SubsectionsSearchResult>("/search/subsections/", { params });

        return data;
    };

    themes = async (query: string, gradesIn: number[], offset?: number): Promise<SearchService.ThemesSearchResult> => {
        const params = new URLSearchParams();
        if (query.length > 0) {
            params.set("search", query);
        }
        if (gradesIn.length > 0) {
            params.set("grades_in", gradesIn.join(","));
        }

        if (offset) {
            params.set("offset", offset.toString());
        }

        const { data } = await agent.get<SearchService.ThemesSearchResult>("/search/themes/", { params });

        return data;
    };

    synopses = async (
        search: string,
        theoryVerifiedFilter: boolean | null,
        seoVerifiedFilter: boolean | null,
        allVerifiedFilter: boolean | null,
        signal?: AbortSignal
    ): Promise<SynopsisMinimal[]> => {
        const params = {
            search,
            theory_verified: theoryVerifiedFilter ?? void 0,
            seo_verified: seoVerifiedFilter ?? void 0,
            all_verified: allVerifiedFilter ?? void 0,
        };

        const { data } = await agent.get<TSynopsisSearchResponse>("/synopses/search/", { signal, params });

        return data.hits.map(mapSynopsisSearchResult);
    };
}

export namespace SearchService {
    export type SectionsSearchResult = {
        sections: ItemSearchResult[];
        subjects: SubjectSearchBreadcrumbs[];
    };
    export type SubsectionsSearchResult = {
        subsections: ItemSearchResult[];
        sections: SectionSearchBreadcrumbs[];
        subjects: SubjectSearchBreadcrumbs[];
    };
    export type ThemesSearchResult = {
        themes: ItemSearchResult[];
        subsections: SubsectionSearchBreadcrumbs[];
        sections: SectionSearchBreadcrumbs[];
        subjects: SubjectSearchBreadcrumbs[];
        estimated_total_hits: number;
    };

    export type ItemSearchResult = { id: number; name: string };

    export type SubsectionSearchBreadcrumbs = ItemSearchResult & { themes: number[] };
    export type SectionSearchBreadcrumbs = ItemSearchResult & { subsections: number[] };
    export type SubjectSearchBreadcrumbs = ItemSearchResult & { sections: number[] };
}
