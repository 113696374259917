import { action, makeObservable, observable } from "mobx";

import { compareSimple, generateId } from "@viuch/shared/utils/data";

import { ExamTask } from "./ExamTask";

export class Exam {
    readonly id: number;
    @observable name: string;
    @observable shortName: string;
    @observable slug: string;
    @observable metaTitle: string;
    @observable metaDescription: string;
    @observable landingMetaTitle: string;
    @observable landingMetaDescription: string;
    @observable tasks: ExamTask[];
    @observable approximateDate: Date;
    @observable allowedTimeSeconds: number;
    @observable.ref preambleId: number | null;
    @observable.shallow gradeIds: number[];
    @observable.ref isArchived: boolean;
    @observable.ref gradingDisplay: Exam.TGradingDisplay;
    @observable.ref gradingRanges: Exam.TGradingRanges | undefined;
    @observable.ref isGenerated: boolean;
    @observable.ref targetGoal: number;
    @observable.ref preparationPeriodSeconds: number;
    @observable.ref priorityValue: Exam.ExamPriority;

    constructor(init: Exam.Init) {
        this.id = init.id;
        this.name = init.name;
        this.shortName = init.shortName;
        this.tasks = init.tasks.slice();
        this.approximateDate = init.approximateDate;
        this.allowedTimeSeconds = init.allowedTimeSeconds;
        this.slug = init.slug;
        this.metaTitle = init.metaTitle;
        this.metaDescription = init.metaDescription;
        this.landingMetaTitle = init.landingMetaTitle;
        this.landingMetaDescription = init.landingMetaDescription;
        this.preambleId = init.preambleId;
        this.gradeIds = init.gradeIds.slice();
        this.isArchived = init.isArchived;
        this.gradingDisplay = init.gradingDisplay;
        this.gradingRanges = init.gradingRanges;
        this.isGenerated = init.isGenerated;
        this.targetGoal = init.targetGoal;
        this.preparationPeriodSeconds = init.preparationPeriodSeconds;
        this.priorityValue = init.priorityValue;

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setGradingDisplay(key: string) {
        switch (this.gradingDisplay) {
            case "both": {
                this.gradingDisplay = key === "grade" ? "score" : "grade";
                break;
            }
            case "grade": {
                if (key === "grade") {
                    return;
                } else {
                    this.gradingDisplay = "both";
                    break;
                }
            }
            case "score": {
                if (key === "score") {
                    return;
                } else {
                    this.gradingDisplay = "both";
                    break;
                }
            }
        }
    }

    @action.bound
    removeGradingRanges() {
        this.gradingRanges = undefined;
    }

    @action.bound
    setGradingRange(index: number, value: number) {
        this.gradingRanges?.score_limits.splice(index, 1, value);
    }

    @action.bound
    setShortName(shortName: string) {
        this.shortName = shortName;
    }

    @action.bound
    setSlug(slug: string) {
        this.slug = slug;
    }

    @action.bound
    setMetaTitle(title: string) {
        this.metaTitle = title;
    }

    @action.bound
    setMetaDescription(description: string) {
        this.metaDescription = description;
    }

    @action.bound
    setLandingMetaTitle(title: string) {
        this.landingMetaTitle = title;
    }

    @action.bound
    setLandingMetaDescription(description: string) {
        this.landingMetaDescription = description;
    }

    @action.bound
    setAllowedTime(seconds: number) {
        this.allowedTimeSeconds = seconds;
    }

    @action.bound
    addTask(task: ExamTask) {
        this.tasks.push(task);
    }

    @action.bound
    addNewTask() {
        this.tasks.push(
            new ExamTask({
                id: generateId(),
                name: "",
                problemIds: [],
                themeIds: [],
                score: 0,
            })
        );
    }

    @action.bound
    removeTask(index: number) {
        this.tasks.splice(index, 1);
    }

    @action.bound
    downTask(index: number) {
        if (this.tasks.length >= 2 && index >= 1) {
            [this.tasks[index], this.tasks[index - 1]] = [this.tasks[index - 1], this.tasks[index]];
        }
    }

    @action.bound
    upTask(index: number) {
        if (this.tasks.length >= 2 && index <= this.tasks.length - 2) {
            [this.tasks[index], this.tasks[index + 1]] = [this.tasks[index + 1], this.tasks[index]];
        }
    }

    @action.bound
    setPreambleId(preambleId: number | null) {
        this.preambleId = preambleId;
    }

    equalsTo(other: Exam.Init) {
        switch (false) {
            case compareSimple(this.name, other.name):
            case compareSimple(this.shortName, other.shortName):
            case compareSimple(this.tasks.length, other.tasks.length):
                return false;
        }

        for (let i = 0; i < this.tasks.length; i++) {
            if (!this.tasks[i].equalsTo(other.tasks[i])) {
                return false;
            }
        }

        return true;
    }

    @action.bound
    addGradeId(gradeId: number) {
        if (this.gradeIds.includes(gradeId)) return;

        this.gradeIds.push(gradeId);
    }

    @action.bound
    removeGradeId(gradeId: number) {
        this.gradeIds.remove(gradeId);
    }

    @action.bound
    setIsArchived(v: boolean) {
        this.isArchived = v;
    }

    @action.bound
    setTargetGoal(value: number) {
        this.targetGoal = value;
    }

    @action.bound
    setGradeIds(ids: number[]) {
        this.gradeIds = ids;
    }

    @action.bound
    setApproximateDate(date: Date) {
        this.approximateDate = date;
    }

    @action.bound
    setPreparationPeriodSeconds(value: number) {
        this.preparationPeriodSeconds = value;
    }

    @action.bound
    setPriorityValue(value: number) {
        this.priorityValue = value;
    }

    clone() {
        return new Exam({
            id: this.id,
            name: this.name,
            shortName: this.shortName,
            tasks: this.tasks.map((task) => task.clone()),
            allowedTimeSeconds: this.allowedTimeSeconds,
            approximateDate: this.approximateDate,
            slug: this.slug,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            landingMetaTitle: this.landingMetaTitle,
            landingMetaDescription: this.landingMetaDescription,
            preambleId: this.preambleId,
            gradeIds: this.gradeIds,
            isArchived: this.isArchived,
            gradingDisplay: this.gradingDisplay,
            gradingRanges: this.gradingRanges,
            isGenerated: this.isGenerated,
            targetGoal: this.targetGoal,
            priorityValue: this.priorityValue,
            preparationPeriodSeconds: this.preparationPeriodSeconds,
        });
    }
}

export namespace Exam {
    export enum ExamPriority {
        low = 1,
        medium = 2,
        high = 4,
    }

    export type TGradingRanges = {
        aliases: `${number}`[];
        score_limits: (number | undefined)[];
    };

    export type TGradingDisplay = "score" | "grade" | "both";

    export type Init = {
        id: number;
        name: string;
        shortName: string;
        tasks: ExamTask[];
        approximateDate: Date;
        allowedTimeSeconds: number;
        slug: string;
        metaTitle: string;
        metaDescription: string;
        landingMetaTitle: string;
        landingMetaDescription: string;
        preambleId: number | null;
        gradeIds: number[];
        isArchived: boolean;
        gradingDisplay: TGradingDisplay;
        gradingRanges?: TGradingRanges;
        targetGoal: number;
        isGenerated: boolean;
        preparationPeriodSeconds: number;
        priorityValue: ExamPriority;
    };
}
